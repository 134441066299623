import React, { useContext, useEffect, useState } from "react";

import axios from "axios";
import classnames from "classnames";
import { StaticImage } from "gatsby-plugin-image";

import Context from "../../utils/context";

import Container from "../Container";
import Loader from "../Loader";
import Section from "../Section";
import { OPTION } from "../../utils/language";

const RSVP = () => {
  const [language] = useContext(Context);

  const [additionalAdults, setAdditionalAdults] = useState<string>("");
  const [additionalAdultsDirty, setAdditionalAdultsDirty] =
    useState<boolean>(false);
  const [additionalAdultsValid, setAdditionalAdultsValid] =
    useState<boolean>(false);
  const [additionalChildren, setAdditionalChildren] = useState<string>("");
  const [additionalChildrenDirty, setAdditionalChildrenDirty] =
    useState<boolean>(false);
  const [additionalChildrenValid, setAdditionalChildrenValid] =
    useState<boolean>(false);
  const [attending, setAttending] = useState<boolean | null>(null);
  const [attendingDirty, setAttendingDirty] = useState<boolean>(false);
  const [attendingValid, setAttendingValid] = useState<boolean>(false);
  const [attendingMass, setAttendingMass] = useState<boolean | null>(null);
  const [attendingMassDirty, setAttendingMassDirty] = useState<boolean>(false);
  const [attendingMassValid, setAttendingMassValid] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>("");
  const [firstNameDirty, setFirstNameDirty] = useState<boolean>(false);
  const [firstNameValid, setFirstNameValid] = useState<boolean>(false);
  const [lastName, setLastName] = useState<string>("");
  const [lastNameDirty, setLastNameDirty] = useState<boolean>(false);
  const [lastNameValid, setLastNameValid] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [submittable, setSubmittable] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const errorClassName = "text-xs text-red-600 mt-2";

  const onAdditionalAdultsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.validity.valid) {
      setAdditionalAdults(event.target.value);
      setAdditionalAdultsValid(event.target.value !== "");
    }
    setAdditionalAdultsDirty(true);
  };

  const onAdditionalChildrenChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.validity.valid) {
      setAdditionalChildren(event.target.value);
      setAdditionalChildrenValid(event.target.value !== "");
    }
    setAdditionalChildrenDirty(true);
  };

  const onAttendingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAttending(event.target.value === "true");
    if (event.target.value === "true" && !attendingDirty) {
      setAdditionalAdults("0");
      setAdditionalChildren("0");
    }
    setAttendingDirty(true);
    setAttendingValid(true);
  };

  const onAttendingMassChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAttendingMass(event.target.value === "true");
    setAttendingMassDirty(true);
    setAttendingMassValid(true);
  };

  const onFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value);
    setFirstNameDirty(true);
    setFirstNameValid(event.target.value !== "");
  };

  const onLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
    setLastNameDirty(true);
    setLastNameValid(event.target.value !== "");
  };

  const onSubmitAttempt = () => {
    setFirstNameDirty(true);
    setLastNameDirty(true);
    setAttendingDirty(true);
    if (attending) {
      setAdditionalAdultsDirty(true);
      setAdditionalChildrenDirty(true);
      setAttendingMassDirty(true);
    }
  };

  const submit = (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    setSubmitting(true);
    setSubmitError(false);

    axios
      .post("/.netlify/functions/add-rsvp", {
        firstName,
        lastName,
        attending,
        additionalAdults: attending ? Number(additionalAdults) : 0,
        additionalChildren: attending ? Number(additionalChildren) : 0,
        attendingMass: attending ? attendingMass : false,
      })
      .then(() => setSubmitted(true))
      .catch(() => setSubmitError(true))
      .finally(() => setSubmitting(false));
  };

  const isSubmittable = () => {
    if (submitting) {
      return false;
    }

    if (!firstNameValid || !lastNameValid || !attendingValid) {
      return false;
    }

    if (!attending) {
      return true;
    }

    return (
      attendingMassValid && additionalAdultsValid && additionalChildrenValid
    );
  };

  useEffect(() => {
    setSubmittable(isSubmittable());
  }, [
    additionalAdults,
    additionalChildren,
    attending,
    attendingValid,
    attendingMass,
    attendingMassValid,
    firstName,
    firstNameValid,
    lastName,
    lastNameValid,
    submitting,
  ]);

  return (
    <Section id="rsvp" className="relative text-black">
      <>
        <div className="absolute hidden 2xl:block">
          <StaticImage
            src="../../images/flowers.jpg"
            alt="Flowers"
            aspectRatio={2.2}
          />
        </div>
        <div className="absolute hidden xl:block 2xl:hidden">
          <StaticImage
            src="../../images/flowers.jpg"
            alt="Flowers"
            aspectRatio={1.8}
          />
        </div>
        <div className="absolute hidden lg:block xl:hidden">
          <StaticImage
            src="../../images/flowers.jpg"
            alt="Flowers"
            aspectRatio={1.5}
          />
        </div>
        <div className="absolute hidden md:block lg:hidden">
          <StaticImage
            src="../../images/flowers.jpg"
            alt="Flowers"
            aspectRatio={1.1}
          />
        </div>
        <div className="absolute hidden sm:block md:hidden">
          <StaticImage
            src="../../images/flowers.jpg"
            alt="Flowers"
            aspectRatio={0.9}
          />
        </div>
        <div className="absolute block sm:hidden">
          <StaticImage
            src="../../images/flowers.jpg"
            alt="Flowers"
            aspectRatio={0.7}
          />
        </div>
        <Container
          title={language === OPTION.ENGLISH ? "RSVP" : "Asistencia"}
          dividerColor="white"
          className="text-white z-10"
        >
          <div className="relative flex justify-center items-center rounded-xl bg-white/80 px-4 pt-8 pb-4 text-black">
            <div className={classnames("flex-grow", { invisible: submitted })}>
              <form onSubmit={submit}>
                <div
                  className={classnames(
                    "text-center !mt-0 mb-4",
                    errorClassName,
                    {
                      invisible: !submitError,
                    }
                  )}
                >
                  {language === OPTION.ENGLISH
                    ? "There was a problem submitting your RSVP. Please try again."
                    : "Hubo un problema al enviar su asistencia. Por favor, inténtalo de nuevo."}
                </div>
                <div className="grid gap-6 grid-cols-2 mb-4">
                  <div>
                    <input
                      id="first-name"
                      type="text"
                      value={firstName}
                      onChange={onFirstNameChange}
                      placeholder={
                        language === OPTION.ENGLISH
                          ? "First Name *"
                          : "Nombre *"
                      }
                      className="w-full text-black placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 px-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                    />
                    <div
                      className={classnames(errorClassName, {
                        invisible: !firstNameDirty || firstNameValid,
                      })}
                    >
                      {language === OPTION.ENGLISH
                        ? "Please provide your first name."
                        : "Por favor escribe tu nombre."}
                    </div>
                  </div>
                  <div>
                    <input
                      id="last-name"
                      type="text"
                      value={lastName}
                      onChange={onLastNameChange}
                      placeholder={
                        language === OPTION.ENGLISH
                          ? "Last Name *"
                          : "Apellido *"
                      }
                      className="w-full text-black placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 px-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                    />
                    <div
                      className={classnames(errorClassName, {
                        invisible: !lastNameDirty || lastNameValid,
                      })}
                    >
                      {language === OPTION.ENGLISH
                        ? "Please provide your last name."
                        : "Por favor escribe tu apellido."}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-center text-lg mb-4">
                  <div onChange={onAttendingChange}>
                    <div className="mb-2">
                      <input
                        id="accept"
                        type="radio"
                        value="true"
                        name="attending"
                      />
                      <label htmlFor="accept" className="ml-2">
                        {language === OPTION.ENGLISH
                          ? "Yes, I am attending"
                          : "Si, asistiré"}
                      </label>
                    </div>
                    <div>
                      <input
                        id="decline"
                        type="radio"
                        value="false"
                        name="attending"
                      />
                      <label htmlFor="decline" className="ml-2">
                        {language === OPTION.ENGLISH
                          ? "I regretfully decline"
                          : "Lamentablemente, no asistiré"}
                      </label>
                    </div>
                  </div>
                  <div
                    className={classnames(errorClassName, {
                      invisible: !attendingDirty || attendingValid,
                    })}
                  >
                    {language === OPTION.ENGLISH
                      ? "Please select an option above."
                      : "Selecciona una opción arriba."}
                  </div>
                </div>
                <div
                  className={classnames({
                    "opacity-50": !attending,
                    "cursor-not-allowed": !attending,
                  })}
                >
                  <div
                    className={classnames({
                      "pointer-events-none": !attending,
                    })}
                  >
                    <div className="mb-8">
                      <div className="mb-1">
                        {language === OPTION.ENGLISH
                          ? "Additional Guests"
                          : "Invitados adicionales"}
                      </div>
                      <div className="ml-4 text-sm">
                        <div className="flex items-center mb-1">
                          <div style={{ width: "180px" }}>
                            {language === OPTION.ENGLISH ? "Adults" : "Adultos"}{" "}
                            <span className="text-xs">
                              (
                              {language === OPTION.ENGLISH
                                ? "Don't include yourself"
                                : "No te incluyas a ti mismo"}
                              )
                            </span>
                          </div>
                          <input
                            id="additional-adults"
                            type="number"
                            min={0}
                            value={additionalAdults}
                            onChange={onAdditionalAdultsChange}
                            className="text-black placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 px-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                            style={{ maxWidth: "70px" }}
                            disabled={!attending}
                          />
                          <div
                            className={classnames(errorClassName, "ml-2 mt-0", {
                              invisible:
                                !attending ||
                                !additionalAdultsDirty ||
                                additionalAdultsValid,
                            })}
                          >
                            {language === OPTION.ENGLISH
                              ? "Please provide a number."
                              : "Por favor escribe un número."}
                          </div>
                        </div>
                        <div className="flex items-center">
                          <div style={{ width: "180px" }}>
                            {language === OPTION.ENGLISH ? "Children" : "Niños"}{" "}
                            <span className="text-xs">
                              (
                              {language === OPTION.ENGLISH
                                ? "Age 7 and below"
                                : "7 años y menos"}
                              )
                            </span>
                          </div>
                          <input
                            id="additional-children"
                            type="number"
                            min={0}
                            value={additionalChildren}
                            onChange={onAdditionalChildrenChange}
                            className="text-black placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 px-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                            style={{ maxWidth: "70px" }}
                            disabled={!attending}
                          />
                          <div
                            className={classnames(errorClassName, "ml-2 mt-0", {
                              invisible:
                                !attending ||
                                !additionalChildrenDirty ||
                                additionalChildrenValid,
                            })}
                          >
                            {language === OPTION.ENGLISH
                              ? "Please provide a number."
                              : "Por favor escribe un número."}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-4">
                      <div className="mb-3">
                        {language === OPTION.ENGLISH
                          ? "Will you attend the mass ceremony?"
                          : "¿Asistirás a la misa?"}
                      </div>
                      <div onChange={onAttendingMassChange} className="ml-4">
                        <div className="flex">
                          <div className="mr-4">
                            <input
                              id="mass-attending"
                              type="radio"
                              value="true"
                              name="mass-attending"
                              disabled={!attending}
                            />
                            <label htmlFor="mass-attending" className="ml-2">
                              {language === OPTION.ENGLISH ? "Yes" : "Sí"}
                            </label>
                          </div>
                          <div>
                            <input
                              id="mass-not-attending"
                              type="radio"
                              value="false"
                              name="mass-attending"
                              disabled={!attending}
                            />
                            <label
                              htmlFor="mass-not-attending"
                              className="ml-2"
                            >
                              No
                            </label>
                          </div>
                        </div>
                        <div
                          className={classnames(errorClassName, {
                            invisible:
                              !attending ||
                              !attendingMassDirty ||
                              attendingMassValid,
                          })}
                        >
                          {language === OPTION.ENGLISH
                            ? "Please select an option above."
                            : "Selecciona una opción arriba."}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-center">
                  <div
                    onMouseEnter={onSubmitAttempt}
                    className={classnames({
                      "cursor-not-allowed": !submittable,
                    })}
                  >
                    <button
                      type="submit"
                      className={classnames(
                        "flex items-center text-2xl border text-white border-pink bg-pink rounded-xl px-4 py-2",
                        {
                          "pointer-events-none": !submittable,
                          "opacity-50": !submittable,
                        }
                      )}
                      disabled={!submittable}
                    >
                      <Loader
                        className={classnames("mr-2", {
                          invisible: !submitting,
                        })}
                      />
                      <div>
                        {language === OPTION.ENGLISH ? "Submit" : "Enviar"}
                      </div>
                      <Loader className="ml-2 invisible" />
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div
              className={classnames("absolute text-center p-4", {
                invisible: !submitted,
              })}
            >
              <div className="font-dancing-script text-2xl mb-8">
                To my friends and family,
              </div>
              <div className="text-5xl mb-8">THANK YOU</div>
              <div className="font-dancing-script text-2xl mb-8">
                {attending ? (
                  <>
                    for being part of this special day with us and always making
                    my life better with your love. You brighten my days with joy
                    and happiness. I hope you enjoy celebrating with me on my
                    special day.
                  </>
                ) : (
                  <>
                    for being part of my life and making it great with your
                    love. You brighten my days with joy and happiness. I am
                    sorry to hear that we will miss you during my celebration,
                    but I look forward to making more memories with you in the
                    future.
                  </>
                )}
              </div>
              <div className="font-dancing-script text-2xl">Much love,</div>
              <div className="text-3xl">Adriana Quiroz</div>
            </div>
          </div>
        </Container>
      </>
    </Section>
  );
};

export default RSVP;

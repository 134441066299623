import React from "react";

import styled from "styled-components";
import PropTypes from "prop-types";

const StyledLoader = styled.div`
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: ${({ size }: { size: number }) => size}px;
  height: ${({ size }: { size: number }) => size}px;
  animation: spin 2s linear infinite;
`;

const Loader = ({ size, className }: { size: number; className?: string }) => (
  <StyledLoader size={size} className={className} />
);

Loader.defaultProps = {
  className: "",
  size: 25,
};

Loader.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
};

export default Loader;

import styled from "styled-components";

const RainbowBackground = styled.div`
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) -8.81%,
      #fff 34.08%
    ),
    linear-gradient(
      270deg,
      rgba(42, 240, 228, 0.4) 0%,
      rgba(140, 51, 255, 0.4) 37.5%,
      rgba(255, 39, 118, 0.4) 68.75%,
      rgba(255, 188, 15, 0.4) 100%
    ),
    #fff;
`;

export default RainbowBackground;

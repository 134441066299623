import React from "react";
import PropTypes from "prop-types";

const Section = ({
  children,
  className,
  id,
}: {
  children?: React.ReactNode;
  className?: string;
  id: string;
}) => (
  <div id={id} className={className} style={{ scrollMarginTop: "88px" }}>
    {children}
  </div>
);

Section.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
};

export default Section;

import React, { useContext } from "react";

import {
  faCalendarDay,
  faClock,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StaticImage } from "gatsby-plugin-image";

import Context from "../../utils/context";

import Container from "../Container";
import Section from "../Section";
import Slider from "../Slider";
import { OPTION } from "../../utils/language";

const EventDetails = () => {
  const [language] = useContext(Context);

  return (
    <Section id="event-details">
      <Container
        dividerColor="white"
        title={language === OPTION.ENGLISH ? "Event Details" : "Evento"}
      >
        <>
          <div className="md:grid md:grid-flow-col gap-0 md:gap-6 grid-cols-1 md:grid-cols-2 mb-4">
            <div className="flex flex-col bg-white text-pink rounded-xl px-4 py-4 mb-4 md:mb-0">
              <h3 className="font-dancing-script text-center">
                {language === OPTION.ENGLISH ? "Ceremony" : "Misa"}
              </h3>
              <div className="text-center text-xl mb-4">
                {language === OPTION.ENGLISH
                  ? "San Fernando Cathedral"
                  : "Catedral San Fernando"}
              </div>
              <div className="flex-grow flex flex-col">
                <div className="mb-4">
                  <FontAwesomeIcon
                    icon={faCalendarDay}
                    size="1x"
                    className="mr-4"
                  />
                  <span>
                    {language === OPTION.ENGLISH
                      ? "September 3rd, 2022"
                      : "3 de Septiembre, 2022"}
                  </span>
                </div>
                <div className="mb-4">
                  <FontAwesomeIcon icon={faClock} size="1x" className="mr-4" />
                  <span>5:00 PM</span>
                </div>
                <div className="md:mb-6">
                  <a
                    href="https://goo.gl/maps/7QgfS1R5MQSp1EsG6"
                    target="_blank"
                    rel="noreferrer"
                    className="inline-block"
                  >
                    <div className="flex">
                      <div>
                        <FontAwesomeIcon
                          icon={faLocationDot}
                          size="1x"
                          className="mr-4"
                        />
                      </div>
                      <div>
                        <div>115 Main Plaza</div>
                        <div>San Antonio, TX 78205</div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="flex-grow flex items-center mt-4">
                  <Slider
                    items={[
                      <StaticImage
                        src="../../images/event-locations/san-fernando-cathedral/1.jpeg"
                        alt="San Fernando Cathedral 1"
                      />,
                      <StaticImage
                        src="../../images/event-locations/san-fernando-cathedral/2.jpeg"
                        alt="San Fernando Cathedral 2"
                      />,
                      <StaticImage
                        src="../../images/event-locations/san-fernando-cathedral/3.jpeg"
                        alt="San Fernando Cathedral 3"
                      />,
                    ]}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col bg-white text-pink rounded-xl px-4 py-4">
              <h3 className="font-dancing-script text-center">
                {language === OPTION.ENGLISH ? "Reception" : "Recepción"}
              </h3>
              <div className="text-center text-xl mb-4">
                Plaza Juarez{" "}
                <span className="text-xs">
                  {language === OPTION.ENGLISH ? "by the" : "junto a el"}
                </span>{" "}
                River Walk
              </div>
              <div className="flex-grow flex flex-col">
                <div className="mb-4">
                  <FontAwesomeIcon
                    icon={faCalendarDay}
                    size="1x"
                    className="mr-4"
                  />
                  <span>
                    {language === OPTION.ENGLISH
                      ? "September 3rd, 2022"
                      : "3 de Septiembre, 2022"}
                  </span>
                </div>
                <div className="mb-4">
                  <FontAwesomeIcon icon={faClock} size="1x" className="mr-4" />
                  <span>6:00 PM</span>
                </div>
                <div>
                  <a
                    href="https://goo.gl/maps/mqkGgx3oHhgwyCc19"
                    target="_blank"
                    rel="noreferrer"
                    className="inline-block"
                  >
                    <div className="flex">
                      <div>
                        <FontAwesomeIcon
                          icon={faLocationDot}
                          size="1x"
                          className="mr-4"
                        />
                      </div>
                      <div>
                        <div>La Villita Historic Village</div>
                        <div>Villita Street</div>
                        <div>San Antonio, TX 78205</div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="flex-grow flex items-center mt-4">
                  <Slider
                    items={[
                      <StaticImage
                        src="../../images/event-locations/plaza-juarez/1.jpeg"
                        alt="Plaza Juarez Event Center 1"
                      />,
                      <StaticImage
                        src="../../images/event-locations/plaza-juarez/2.jpeg"
                        alt="Plaza Juarez Event Center 2"
                      />,
                      <StaticImage
                        src="../../images/event-locations/plaza-juarez/3.jpeg"
                        alt="Plaza Juarez Event Center 3"
                      />,
                      <StaticImage
                        src="../../images/event-locations/plaza-juarez/4.jpeg"
                        alt="Plaza Juarez Event Center 4"
                      />,
                      <StaticImage
                        src="../../images/event-locations/plaza-juarez/5.jpeg"
                        alt="Plaza Juarez Event Center 5"
                      />,
                      <StaticImage
                        src="../../images/event-locations/plaza-juarez/6.jpeg"
                        alt="Plaza Juarez Event Center 6"
                      />,
                      <StaticImage
                        src="../../images/event-locations/la-villita/1.webp"
                        alt="La Villita 1"
                      />,
                      <StaticImage
                        src="../../images/event-locations/la-villita/2.webp"
                        alt="La Villita 2"
                      />,
                      <StaticImage
                        src="../../images/event-locations/la-villita/3.jpeg"
                        alt="La Villita 3"
                      />,
                      <StaticImage
                        src="../../images/event-locations/la-villita/4.jpeg"
                        alt="La Villita 4"
                      />,
                      <StaticImage
                        src="../../images/event-locations/la-villita/5.jpeg"
                        alt="La Villita 5"
                      />,
                      <StaticImage
                        src="../../images/event-locations/river-walk/1.jpeg"
                        alt="River Walk 1"
                      />,
                      <StaticImage
                        src="../../images/event-locations/river-walk/2.jpeg"
                        alt="River Walk 2"
                      />,
                      <StaticImage
                        src="../../images/event-locations/river-walk/3.jpeg"
                        alt="River Walk 3"
                      />,
                      <StaticImage
                        src="../../images/event-locations/river-walk/4.jpeg"
                        alt="River Walk 4"
                      />,
                      <StaticImage
                        src="../../images/event-locations/river-walk/5.jpeg"
                        alt="River Walk 5"
                      />,
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end">
            <a href="#event-contributors" className="underline text-pink">
              {language === OPTION.ENGLISH
                ? "Event Contributors"
                : "Colaboradores del Evento"}
            </a>
          </div>
        </>
      </Container>
    </Section>
  );
};

export default EventDetails;

import React, { useContext } from "react";

import { StaticImage } from "gatsby-plugin-image";

import Context from "../../utils/context";

import Container from "../Container";
import CourtMember from "../CourtMember";
import Section from "../Section";
import { OPTION } from "../../utils/language";

const Court = () => {
  const [language] = useContext(Context);

  const chamberlanes = [
    {
      about: (
        <div>
          <div className="mb-2">
            {language === OPTION.ENGLISH
              ? "AJ has a great interest in computer engineering. He has been a Boyscout for almost ten years, working toward his Eagle rank. He loves to travel, bike, camp and when home, play lots of video games! He found a love of music first through piano, which has given him the opportunity to be a part of middle and high school percussion."
              : "AJ tiene un gran interés en la ingeniería de computación. Ha sido un Boyscout por casi diez años, trabajando para alcanzar su rango de Águila. Le encanta viajar, andar en bicicleta, acampar y, cuando está en casa, ¡jugar muchos videojuegos! Primero encontró el amor por la música a través del piano, lo que le ha dado la oportunidad de ser parte de la percusión de la escuela primaria y secundaria."}
          </div>
          <div>
            {language === OPTION.ENGLISH
              ? "Adriana and Aiden met in band and have been friends since middle school."
              : "Adriana y Aiden se conocieron en la banda y son amigos desde la secundaria."}
          </div>
        </div>
      ),
      age: 14,
      background: { color: "court-purple", swirlType: "single" },
      image: (
        <StaticImage
          src="../../images/court/chamberlanes/aiden-sopala.jpg"
          alt="Aiden Sopala"
          placeholder="blurred"
          width={180}
          height={240}
          transformOptions={{ trim: 15 }}
          className="rounded-xl"
        />
      ),
      name: { first: "Aiden", middle: '"AJ"', last: "Sopala" },
      school:
        language === OPTION.ENGLISH ? "STEM at L.E.E. HS" : "STEM en L.E.E. HS",
    },
    {
      about: (
        <>
          <div className="mb-2">
            {language === OPTION.ENGLISH
              ? "Nicolas is a member of the National Junior Honor Society, STEM UIL district team, advanced theater program, track, and cross country. He enjoys reading, being with family, hanging out with friends, playing video games, and making people laugh."
              : "Nicolás es miembro de la Sociedad Nacional de Honor Junior, equipo del distrito STEM UIL, programa de teatro avanzado, atletismo y campo traviesa. Le gusta leer, estar con la familia, salir con amigos, jugar videojuegos y hacer reír a la gente."}
          </div>
          <div>
            {language === OPTION.ENGLISH
              ? "Adriana and Nicolas became friends over their love of comic books. It was a funny story - ask Nic."
              : "Adriana y Nicolás se hicieron amigos por su amor a los cómics. Fue una historia graciosa, pregúntenle a Nic."}
          </div>
        </>
      ),
      age: 14,
      background: { color: "court-blue", swirlType: "single" },
      image: (
        <StaticImage
          src="../../images/court/chamberlanes/nicolas-carrillo.jpg"
          alt="Nicolas Carrillo"
          placeholder="blurred"
          width={180}
          height={240}
          transformOptions={{ trim: 15 }}
          className="rounded-xl"
        />
      ),
      name: { first: "Nicolas", last: "Carrillo" },
      school:
        language === OPTION.ENGLISH ? "STEM at L.E.E. HS" : "STEM en L.E.E. HS",
    },
    {
      age: 14,
      about: (
        <>
          <div className="mb-2">
            {language === OPTION.ENGLISH
              ? "Kharlo has been playing the clarinet for 2 years and also practices saxophone. His hobbies include music, science, and video games. He has started writing his own book and recently joined a bible study group. Kharlo enjoys hanging out with friends and loves Koko and Nacho, his 2 dogs."
              : "Kharlo toca el clarinete desde hace 2 años y también practica el saxofón. Sus pasatiempos incluyen la música, la ciencia y los videojuegos. Comenzó a escribir su propio libro y recientemente se unió a un grupo de estudio bíblico. Kharlo disfruta salir con amigos y ama a Koko y Nacho, sus 2 perros."}
          </div>
          <div>
            {language === OPTION.ENGLISH
              ? "Adriana and Kharlo have been friends since elementary, which is over 6 years."
              : "Adriana y Kharlo han sido amigos desde la primaria, que son más de 6 años."}
          </div>
        </>
      ),
      background: { color: "court-yellow", swirlType: "single" },
      image: (
        <StaticImage
          src="../../images/court/chamberlanes/kharlo-rowe.jpeg"
          alt="Kharlo Rowe"
          placeholder="blurred"
          width={180}
          height={240}
          transformOptions={{ trim: 15 }}
          className="rounded-xl"
        />
      ),
      name: { first: "Kharlo", last: "Rowe" },
      school: "Johnson HS",
    },
    {
      about: (
        <>
          <div className="mb-2">
            {language === OPTION.ENGLISH
              ? "Angel will be in band, cross country, track, and swimming at L.E.E. HS."
              : "Angel estará en banda, cross country, track y natación en L.E.E. HS."}
          </div>
          <div>
            {language === OPTION.ENGLISH
              ? "Adriana and Angel met and became friends at Nimitz Middle School over a year ago."
              : "Adriana y Angel se conocieron y se hicieron amigos en la Escuela Primaria Nimitz hace más de un año."}
          </div>
        </>
      ),
      age: 14,
      background: { color: "court-green-dark", swirlType: "single" },
      image: (
        <StaticImage
          src="../../images/court/chamberlanes/angel-ortiz.jpg"
          alt="Angel Ortiz"
          placeholder="blurred"
          width={180}
          height={240}
          transformOptions={{ trim: 15 }}
          className="rounded-xl"
        />
      ),
      name: { first: "Angel", last: "Ortiz" },
      school:
        language === OPTION.ENGLISH ? "STEM at L.E.E. HS" : "STEM en L.E.E. HS",
    },
    {
      about: (
        <>
          <div className="mb-2">
            {language === OPTION.ENGLISH
              ? "Diego is active in Robotics, UIL, and Student Council as well as tennis and orchestra. He plans to attend college and pursue a science-related field."
              : "Diego participa activamente en Robótica, UIL y Consejo Estudiantil, así como en tenis y orquesta. Planea asistir a la universidad y seguir un campo relacionado con la ciencia."}
          </div>
          <div>
            {language === OPTION.ENGLISH
              ? "Adriana and Diego met at Roan Forest Elementary and have been friends since 4th grade. They have sung together in choir and played the xylophone during SONIDO performances."
              : "Adriana y Diego se conocieron en la escuela primaria Roan Forest y son amigos desde el cuarto grado. Han cantado juntos en coro y han tocado el xilófono durante las presentaciones de SONIDO."}
          </div>
        </>
      ),
      age: 14,
      background: { color: "court-blue-teal", swirlType: "single" },
      image: (
        <StaticImage
          src="../../images/court/chamberlanes/diego-espinoza.jpg"
          alt="Diego Espinoza"
          placeholder="blurred"
          width={180}
          height={240}
          transformOptions={{ trim: 15 }}
          className="rounded-xl"
        />
      ),
      name: { first: "Diego", last: "Espinoza" },
      school: "Johnson HS",
    },
    {
      about: (
        <>
          <div className="mb-2">
            {language === OPTION.ENGLISH
              ? "Caden is an athlete that values physical fitness. He plays baseball, soccer, and football. His hobbies include drawing, photography, producing music, and designing cool inventions for everyday use. He has worked at a non-profit organization called Kid Nation as a summer camp instructor. Caden received a Presidential Volunteer Service Award. He looks forward to pursuing a career in mechanical engineering or becoming a doctor."
              : "Caden es un atleta que valora la forma física. Juega béisbol, fútbol y fútbol americano. Sus pasatiempos incluyen dibujar, fotografiar, producir música y diseñar inventos geniales para el uso diario. Ha trabajado en una organización sin fines de lucro llamada Kid Nation como instructor de un campamento de verano. Caden recibió un Premio Presidencial al Servicio Voluntario. Espera seguir una carrera en ingeniería mecánica o convertirse en médico."}
          </div>
          <div>
            {language === OPTION.ENGLISH
              ? "Adriana and Caden met in STEM at Nimitz MS."
              : "Adriana y Caden se conocieron en STEM en Nimitz MS."}
          </div>
        </>
      ),
      age: 14,
      background: { color: "court-green", swirlType: "single" },
      image: (
        <StaticImage
          src="../../images/court/chamberlanes/caden-cruz.jpg"
          alt="Caden Cruz"
          placeholder="blurred"
          width={180}
          height={240}
          transformOptions={{ trim: 15 }}
          className="rounded-xl"
        />
      ),
      name: { first: "Caden", last: "Cruz" },
      school:
        language === OPTION.ENGLISH ? "STEM at L.E.E. HS" : "STEM en L.E.E. HS",
    },
    {
      age: 14,
      about: (
        <div>
          <div className="mb-2">
            {language === OPTION.ENGLISH
              ? "Abraham enjoys music, baseball, and hanging out with friends. He has a great sense of humor and loves video games."
              : "Abraham le gusta la música, el béisbol y disfruta estar con amigos. Tiene un gran sentido del humor y le encantan los videojuegos."}
          </div>
          <div>
            {language === OPTION.ENGLISH
              ? "Adriana and Abraham have been friends since third grade."
              : "Adriana y Abraham son amigos desde tercer grado."}
          </div>
        </div>
      ),
      background: { color: "court-pink", swirlType: "single" },
      image: (
        <StaticImage
          src="../../images/court/chamberlanes/abraham-zavarce-diaz.jpg"
          alt="Abraham Zavarce Díaz"
          placeholder="blurred"
          width={180}
          height={240}
          transformOptions={{ trim: 15 }}
          className="rounded-xl"
        />
      ),
      name: { first: "Abraham", middle: "Zavarce", last: "Díaz" },
      school: "Johnson HS",
    },
    {
      about: (
        <div>
          {language === OPTION.ENGLISH
            ? "Ruben is a big drone enthusiast and plans on working with them in the very near future. He enjoys playing basketball with friends and drawing."
            : "Ruben es un gran entusiasta de los drones y planea trabajar con ellos en un futuro muy cercano. Le gusta jugar baloncesto con amigos y dibujar."}
        </div>
      ),
      age: 14,
      background: { color: "court-orange", swirlType: "single" },
      image: (
        <StaticImage
          src="../../images/court/chamberlanes/ruben-garcia.jpg"
          alt="Ruben Garcia de Arboleya Bazan"
          placeholder="blurred"
          width={180}
          height={240}
          transformOptions={{ trim: 15 }}
          className="rounded-xl"
        />
      ),
      name: { first: "Ruben", last: "Garcia de Arboleya Bazan" },
      school: "Johnson HS",
    },
    {
      about: (
        <div>
          {language === OPTION.ENGLISH
            ? "JD enjoys running, swimming, and playing basketball."
            : "A JD le gusta correr, nadar y jugar baloncesto."}
        </div>
      ),
      age: 11,
      background: { color: "court-red", swirlType: "single" },
      image: (
        <StaticImage
          src="../../images/court/chamberlanes/joe-david-ortiz.jpg"
          alt="Joe David Ortiz"
          placeholder="blurred"
          width={180}
          height={240}
          transformOptions={{ trim: 15 }}
          className="rounded-xl"
        />
      ),
      name: { first: "Joe", middle: "David", last: "Ortiz" },
      school:
        language === OPTION.ENGLISH ? "STEM at Nimitz MS" : "STEM en Nimitz MS",
    },
  ];

  const damas = [
    {
      about: (
        <div>
          <div className="mb-2">
            {language === OPTION.ENGLISH
              ? "Jocelyn is a member of the Girls, Inc, program, has been in choir for 4 years, and has been a singer at the School of Rock for 2 years. She plans on going to college to be a veterinarian and currently volunteers at the Animal Defense League."
              : "Jocelyn es miembro del programa Girls, Inc, ha estado en el coro durante 4 años y ha sido cantante en la Escuela de Rock durante 2 años. Ella planea ir a la universidad para ser veterinaria y actualmente es voluntaria en Animal Defense League."}
          </div>
          <div>
            {language === OPTION.ENGLISH
              ? "Adriana and Jocelyn met and became friends through Girls, Inc. over a year ago."
              : "Adriana y Jocelyn se conocieron y se hicieron amigas a través de Girls, Inc. hace más de un año."}
          </div>
        </div>
      ),
      age: 14,
      background: { color: "court-purple", swirlType: "double" },
      image: (
        <StaticImage
          src="../../images/court/damas/jocelyn-warman.jpg"
          alt="Jocelyn Warman"
          placeholder="blurred"
          width={180}
          height={240}
          transformOptions={{ trim: 15 }}
          className="rounded-xl"
        />
      ),
      name: { first: "Jocelyn", last: "Warman" },
      school: "Johnson HS",
    },
    {
      about: (
        <div>
          {language === OPTION.ENGLISH
            ? "Luz is majoring in musical theater. She plays guitar, piano, and writes songs. Luz plans on becoming a song writer or performing musical theater when she grows up."
            : "Luz se está especializando en teatro musical. Toca la guitarra, el piano y escribe canciones. Luz planea convertirse en compositora o actuar en teatro musical cuando crezca."}
        </div>
      ),
      age: 14,
      background: { color: "court-blue", swirlType: "double" },
      image: (
        <StaticImage
          src="../../images/court/damas/luz-peche.jpeg"
          alt="Luz Peche"
          placeholder="blurred"
          width={180}
          height={240}
          transformOptions={{ trim: 15 }}
          className="rounded-xl"
        />
      ),
      name: { first: "Luz", last: "Peche" },
      school:
        language === OPTION.ENGLISH ? "NESA at L.E.E. HS" : "NESA en L.E.E. HS",
    },
    {
      about: (
        <div>
          <div className="mb-2">
            {language === OPTION.ENGLISH
              ? "Daniela is a member of the Johnson High School cheer and has been doing cheer for 4 years. She plans on getting a scholarship and attending college to be a fashion designer."
              : "Daniela es porrista de Johnson High School y ha estado haciendo porrismo durante 4 años. Ella planea obtener una beca y asistir a la universidad para ser diseñadora de moda."}
          </div>
          <div>
            {language === OPTION.ENGLISH
              ? "Adriana and Daniela have been friends since the 3rd grade and have even been in a Talent Show together!"
              : "Adriana y Daniela han sido amigas desde el 3er grado e incluso han estado juntas en un Show de Talento."}
          </div>
        </div>
      ),
      age: 14,
      background: { color: "court-yellow", swirlType: "double" },
      image: (
        <StaticImage
          src="../../images/court/damas/daniela-diamond.jpg"
          alt="Daniela Diamond"
          placeholder="blurred"
          width={180}
          height={240}
          transformOptions={{ trim: 15 }}
          className="rounded-xl"
        />
      ),
      name: { first: "Daniela", last: "Diamond" },
      school: "Johnson HS",
    },
    {
      about: (
        <>
          <div className="mb-2">
            {language === OPTION.ENGLISH
              ? "Jaslene will be playing volleyball in high school and pursuing her dream career of being a software developer. She also enjoys dancing."
              : "Jaslene jugará voleibol en la escuela secundaria y seguirá la carrera de sus sueños de ser desarrolladora de software. También le gusta bailar."}
          </div>
          <div>
            {language === OPTION.ENGLISH
              ? 'Adriana and Jaslene met in 7th grade playing soccer. Adriana calls her "Jazzy".'
              : 'Adriana y Jaslene se conocieron en séptimo grado jugando al fútbol. Adriana la llama "Jazzy".'}
          </div>
        </>
      ),
      age: 14,
      background: { color: "court-green-dark", swirlType: "double" },
      image: (
        <StaticImage
          src="../../images/court/damas/jaslene-moreno.jpg"
          alt="Jaslene Moreno"
          placeholder="blurred"
          width={180}
          height={240}
          transformOptions={{ trim: 15 }}
          className="rounded-xl"
        />
      ),
      name: { first: "Jaslene", last: "Moreno" },
      school:
        language === OPTION.ENGLISH ? "STEM at L.E.E. HS" : "STEM en L.E.E. HS",
    },
    {
      about: (
        <>
          <div>
            {language === OPTION.ENGLISH
              ? "Aaliyah participates in sports all year round. She is a percussionist for the L.E.E. HS drumline, which is quite the accomplishment for a freshman. She also finds the time to volunteer for her church 3 times a week with kids ministries. Aaliyah plans on playing in the WNBA, picking a profession which helps people, or being a pastor for her church."
              : "Aaliyah participa en deportes durante todo el año. Es percusionista de la L.E.E. HS drumline, que es todo un logro para un estudiante de primer año. Ella también encuentra el tiempo para ser voluntaria en su iglesia 3 veces a la semana con los ministerios de niños. Aaliyah planea jugar en la WNBA, elegir una profesión que ayude a las personas o ser pastora de su iglesia."}
          </div>
        </>
      ),
      age: 14,
      background: { color: "court-blue-teal", swirlType: "double" },
      image: (
        <StaticImage
          src="../../images/court/damas/aaliyah-romero.jpeg"
          alt="Aaliyah Romero"
          placeholder="blurred"
          width={180}
          height={240}
          transformOptions={{ trim: 15 }}
          className="rounded-xl"
        />
      ),
      name: { first: "Aaliyah", last: "Romero" },
      school:
        language === OPTION.ENGLISH ? "STEM at L.E.E. HS" : "STEM en L.E.E. HS",
    },
    {
      about: (
        <>
          <div className="mb-2">
            {language === OPTION.ENGLISH
              ? "Madison is in Student Council, STEM Scholars, SOS, NJHS, academic UIL,  a Life rank in Scouts BSA, in the Order of Arrow, and has been playing the violin for 4 years. She also plays all school sports and enjoys baking, camping, swimming, biking, traveling, and volunteering."
              : "Madison está en el Consejo Estudiantil, STEM Scholars, SOS, NJHS, UIL académico, un rango Life en Scouts BSA, en el Order of Arrow, y tiene 4 años tocando el violín . También practica todos los deportes escolares y disfruta cocinar, acampar, nadar, andar en bicicleta, viajar y ser voluntaria."}
          </div>
          <div>
            {language === OPTION.ENGLISH
              ? "Adriana and Madison met and became friends through STEM in middle school."
              : "Adriana y Madison se conocieron y se hicieron amigas a través de STEM en la escuela secundaria."}
          </div>
        </>
      ),
      age: 14,
      background: { color: "court-green", swirlType: "double" },
      image: (
        <StaticImage
          src="../../images/court/damas/madison-smith.jpg"
          alt="Madison Smith"
          placeholder="blurred"
          width={180}
          height={240}
          transformOptions={{ trim: 15 }}
          className="rounded-xl"
        />
      ),
      name: { first: "Madison", last: "Smith" },
      school:
        language === OPTION.ENGLISH ? "STEM at L.E.E. HS" : "STEM en L.E.E. HS",
    },
    {
      about: (
        <>
          <div className="mb-2">
            {language === OPTION.ENGLISH
              ? "Sofia is a member of the SOS Club, has been in art for 3 years, and was in dance for 5 years. She plans to attend college to become a psychologist."
              : "Sofía es miembro del Club SOS, ha estado en el arte durante 3 años y estuvo en la danza durante 5 años. Ella planea asistir a la universidad para convertirse en psicóloga."}
          </div>
          <div>
            {language === OPTION.ENGLISH
              ? "Adriana and Sofia met about 3 years ago in 6th grade."
              : "Adriana y Sofia se conocieron hace 3 años en sexto grado."}
          </div>
        </>
      ),
      age: 14,
      background: { color: "court-pink", swirlType: "double" },
      image: (
        <StaticImage
          src="../../images/court/damas/sofia-ramos.jpg"
          alt="Sofia Ramos"
          placeholder="blurred"
          width={180}
          height={240}
          transformOptions={{ trim: 15 }}
          className="rounded-xl"
        />
      ),
      name: { first: "Sofia", last: "Ramos" },
      school:
        language === OPTION.ENGLISH ? "STEM at L.E.E. HS" : "STEM en L.E.E. HS",
    },
    {
      about: (
        <>
          <div className="mb-2">
            {language === OPTION.ENGLISH
              ? "Carolina loves being part of theater in school. Some of her hobbies include archery, photography, and art."
              : "A Carolina le encanta ser parte del teatro en la escuela. Algunos de sus pasatiempos incluyen el tiro con arco, la fotografía y el arte."}
          </div>
          <div>
            {language === OPTION.ENGLISH
              ? "Adriana and Carolina met at Euraka program over a year ago."
              : "Adriana y Carolina se conocieron en el programa Euraka hace más de un año."}
          </div>
        </>
      ),
      age: 14,
      background: { color: "court-orange", swirlType: "double" },
      image: (
        <StaticImage
          src="../../images/court/damas/carolina-escamilla-ponce.jpg"
          alt="Carolina Escamilla Ponce"
          placeholder="blurred"
          width={180}
          height={240}
          transformOptions={{ trim: 15 }}
          className="rounded-xl"
        />
      ),
      name: { first: "Carolina", last: "Escamilla Ponce" },
      school: "Young Woman's Leadership Academy",
    },
    {
      about: (
        <>
          <div className="mb-2">
            {language === OPTION.ENGLISH
              ? "Isabela loves to play competitive sports like soccer and track. She was part of the SONIDO musical group for 2 years. She has been dancing folklórico and flamenco with the city of San Antonio since she was 4. Isabela also enjoys being a Girl Scout and volunteering."
              : "A Isabela le encanta practicar deportes competitivos como el fútbol y track. Formó parte del grupo musical SONIDO durante 2 años. Ha estado bailando folklórico y flamenco con la ciudad de San Antonio desde que tenía 4 años. Isabela también disfruta ser Girl Scout y ser voluntaria."}
          </div>
          <div>
            {language === OPTION.ENGLISH
              ? "Adriana is Isabela's older sister."
              : "Adriana es la hermana mayor de Isabela."}
          </div>
        </>
      ),
      age: 11,
      background: { color: "court-red", swirlType: "double" },
      image: (
        <StaticImage
          src="../../images/court/damas/isabela-quiroz.jpg"
          alt="Isabela Quiroz"
          placeholder="blurred"
          width={180}
          height={240}
          transformOptions={{ trim: 15 }}
          className="rounded-xl"
        />
      ),
      name: { first: "Isabela", last: "Quiroz" },
      school:
        language === OPTION.ENGLISH ? "STEM at Nimitz MS" : "STEM en Nimitz MS",
    },
  ];

  return (
    <Section id="court" className="bg-blue-pastel">
      <Container
        dividerColor="black"
        title={language === OPTION.ENGLISH ? "The Court" : "La Corte"}
        className="text-black"
      >
        <div>
          <div className="mb-4">
            <h3 className="font-dancing-script text-center mb-4">
              {language === OPTION.ENGLISH ? (
                "Honorary Chambelán"
              ) : (
                <>
                  Chambelán <span className="text-2xl">de</span> Honor
                </>
              )}
            </h3>
            <div className="flex justify-center">
              <CourtMember
                age={14}
                image={
                  <StaticImage
                    src="../../images/court/carlo-rivera.jpg"
                    alt="Carlo Rivera"
                    placeholder="blurred"
                    width={240}
                    height={320}
                    transformOptions={{ trim: 15 }}
                    className="rounded-xl"
                  />
                }
                name={{ first: "Carlo", last: "Rivera" }}
                school="Ronald Reagan High School"
                background={{ color: "court-gold", swirlType: "loop" }}
              >
                <div>
                  <div className="mb-2">
                    {language === OPTION.ENGLISH
                      ? "Carlo is a member of the Reagan marching band with 3 years of baritone experience. He enjoys looking after his younger brother, Pablo, whom he trains in the art of practical jokes. Carlo also loves playing video games and hanging out with the family dog, Roko!"
                      : "Carlo es miembro de la banda de música Reagan con 3 años de experiencia como barítono. Disfruta cuidando a su hermano menor, Pablo, a quien entrena en el arte de las bromas. ¡A Carlo también le encanta jugar videojuegos y pasar el rato con el perro de la familia, Roko!"}
                  </div>
                  <div>
                    {language === OPTION.ENGLISH
                      ? "Adriana and Carlo have been friends for over six years."
                      : "Adriana y Carlo tienen más de seis años siendo amigos."}
                  </div>
                </div>
              </CourtMember>
            </div>
          </div>
          <div>
            <div className="flex text-black">
              <div className="flex-1">
                <h3 className="font-dancing-script text-center mb-4">Damas</h3>
              </div>
              <div className="hidden md:block flex-1">
                <h3 className="font-dancing-script text-center mb-4">
                  Chambelánes
                </h3>
              </div>
            </div>
            <div
              className="md:grid grid-flow-col gap-6 grid-cols-2 text-black px-4"
              style={{ gridTemplateRows: "repeat(9, minmax(0, 1fr))" }}
            >
              {damas.map(
                ({ about, age, background, image, name, school }, index) => (
                  <CourtMember
                    age={age}
                    image={image}
                    name={name}
                    school={school}
                    background={background}
                    key={index}
                    className="mb-4 md:mb-0"
                  >
                    {about}
                  </CourtMember>
                )
              )}
              {chamberlanes.map(
                ({ about, age, background, image, name, school }, index) => (
                  <CourtMember
                    age={age}
                    image={image}
                    name={name}
                    school={school}
                    background={background}
                    key={index}
                    className="mb-4 last:mb-0 md:mb-0"
                  >
                    {about}
                  </CourtMember>
                )
              )}
            </div>
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default Court;

import React, { useContext } from "react";

import {
  faCalendarDay,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StaticImage } from "gatsby-plugin-image";

import Context from "../../utils/context";

import Countdown from "../Countdown";
import Section from "../Section";
import { OPTION } from "../../utils/language";

const Home = () => {
  const [language] = useContext(Context);

  return (
    <Section
      id="home"
      className="relative flex items-end justify-center xl:items-start xl:justify-end"
    >
      <>
        <StaticImage
          src="../../images/court/adriana/colima.jpg"
          alt="Adriana Colima"
        />
        <div className="absolute flex flex-col items-center bg-white/80 text-black rounded-xl p-4 mb-8 md:mb-24 md:ml-48 lg:mb-44 lg:ml-72 xl:ml-0 xl:mb-0 xl:mt-8 xl:mr-32 2xl:mt-16 2xl:mr-44">
          <div className="flex w-full text-pink text-sm pb-2">
            <div className="flex-1">
              <a
                href="https://calendar.google.com/event?action=TEMPLATE&tmeid=NWozaDdxb3U3ZW41MjZiaTBuZTZlZTdxdHIgMzRrbGI2dnZwZDh2cTQ2amJjdm1jODUyb29AZw&tmsrc=34klb6vvpd8vq46jbcvmc852oo%40group.calendar.google.com"
                target="_blank"
                rel="noreferrer"
                className="inline-block"
              >
                <div className="flex">
                  <div className="mr-2">
                    <FontAwesomeIcon icon={faCalendarDay} size="1x" />
                  </div>
                  <div>
                    <div>
                      {language === OPTION.ENGLISH
                        ? "September 3rd"
                        : "3 de Septiembre"}
                    </div>
                    <div>6:00 PM</div>
                  </div>
                </div>
              </a>
            </div>
            <div className="flex-1 flex justify-end">
              <a
                href="https://goo.gl/maps/mqkGgx3oHhgwyCc19"
                target="_blank"
                rel="noreferrer"
                className="inline-block"
              >
                <div className="flex">
                  <div className="mr-2">
                    <FontAwesomeIcon icon={faLocationDot} size="1x" />
                  </div>
                  <div>
                    <div>Plaza Juarez</div>
                    <div>SA River Walk</div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="font-dancing-script text-3xl xl:text-5xl font-bold pb-2">
            {language === OPTION.ENGLISH
              ? "Adriana's Quinceañera"
              : "Quinceañera de Adriana"}
          </div>
          <Countdown dateTime="Sep 3, 2022 18:00:00" className="text-pink" />
        </div>
      </>
    </Section>
  );
};

export default Home;

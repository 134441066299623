import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import classnames from "classnames";
import styled from "styled-components";
import Context from "../utils/context";
import { OPTION } from "../utils/language";

const StyledSection = styled.div`
  width: 75px;
`;

const Section = ({ remaining, type }: { remaining: number; type: string }) => (
  <StyledSection className="flex flex-col items-center">
    <div className="text-2xl">{remaining}</div>
    <div className="">{type}</div>
  </StyledSection>
);

Section.propTypes = {
  remaining: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
};

const Countdown = ({
  className,
  dateTime,
}: {
  className?: string;
  dateTime: string;
}) => {
  const countDownDate = new Date(dateTime).getTime();

  const [language] = useContext(Context);

  const [days, setDays] = useState<number>(99);
  const [hours, setHours] = useState<number>(23);
  const [minutes, setMinutes] = useState<number>(59);
  const [seconds, setSeconds] = useState<number>(59);

  const update = () => {
    const remaining = countDownDate - new Date().getTime();

    setDays(Math.floor(remaining / (1000 * 60 * 60 * 24)));
    setHours(
      Math.floor((remaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    );
    setMinutes(Math.floor((remaining % (1000 * 60 * 60)) / (1000 * 60)));
    setSeconds(Math.floor((remaining % (1000 * 60)) / 1000));
  };

  useEffect(() => {
    update();
    const timer = setInterval(update, 1000);
    return () => clearInterval(timer);
  });

  return (
    <div className={classnames("flex", className)}>
      <Section
        remaining={days}
        type={language === OPTION.ENGLISH ? "Days" : "Días"}
      />
      <Section
        remaining={hours}
        type={language === OPTION.ENGLISH ? "Hours" : "Horas"}
      />
      <Section
        remaining={minutes}
        type={language === OPTION.ENGLISH ? "Minutes" : "Minutos"}
      />
      <Section
        remaining={seconds}
        type={language === OPTION.ENGLISH ? "Seconds" : "Segundos"}
      />
    </div>
  );
};

Countdown.propTypes = {
  className: PropTypes.string,
  dateTime: PropTypes.string.isRequired,
};

export default Countdown;

import React from "react";
import PropTypes from "prop-types";

import {
  faFacebook,
  faInstagram,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Vendor = ({
  name,
  socialMedia,
}: {
  name: React.ReactNode;
  socialMedia: { type: string; url: string }[];
}) => {
  const icon = {
    Facebook: faFacebook,
    Instagram: faInstagram,
    TikTok: faTiktok,
  };

  return (
    <div className="flex flex-col items-center py-4 px-2">
      <div className="flex-grow flex flex-col justify-start items-center text-center md:text-lg mb-2">
        {name}
      </div>
      <div
        className="grid gap-6"
        style={{
          gridTemplateColumns: `repeat(${socialMedia.length}, minmax(0, 1fr))`,
        }}
      >
        {socialMedia.map(({ type, url }, index) => (
          <div key={index} className="flex justify-center items-center">
            <a href={url} target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon={icon[type]} size="2x" />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

Vendor.propTypes = {
  name: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  socialMedia: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(["Facebook", "Instagram", "TikTok"]).isRequired,
      url: PropTypes.string.isRequired,
    })
  ),
};

export default Vendor;

import React, { useContext, useEffect, useState } from "react";

import axios from "axios";
import classnames from "classnames";
import { faAnglesDown, faFaceFrown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Context from "../../utils/context";

import Container from "../Container";
import Loader from "../Loader";
import Section from "../Section";
import { OPTION } from "../../utils/language";

const enum Status {
  Uninitialized,
  Loading,
  Updating,
  Loaded,
  Error,
}

interface PostsResponse {
  after?: string;
  before?: string;
  posts: {
    id: number;
    firstName: string;
    lastName: string;
    datetime: number;
    message: string;
  }[];
}

const GuestBook = () => {
  interface postInterface {
    id: number;
    firstName: string;
    lastName: string;
    datetime: number;
    message: string;
  }

  const [language] = useContext(Context);

  const [after, setAfter] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [firstNameDirty, setFirstNameDirty] = useState<boolean>(false);
  const [firstNameValid, setFirstNameValid] = useState<boolean>(false);
  const [lastName, setLastName] = useState<string>("");
  const [lastNameDirty, setLastNameDirty] = useState<boolean>(false);
  const [lastNameValid, setLastNameValid] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [messageDirty, setMessageDirty] = useState<boolean>(false);
  const [messageValid, setMessageValid] = useState<boolean>(false);
  const [posts, setPosts] = useState<postInterface[]>([]);
  const [readMoreClicks, setReadMoreClicks] = useState<number>(0);
  const [status, setStatus] = useState<Status>(Status.Uninitialized);
  const [submitError, setSubmitError] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [submittable, setSubmittable] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const errorClassName = "text-xs text-red-600 mt-1";
  const pageSize = 4;
  const BASE_URL = "/.netlify/functions";
  const GET_URL = `${BASE_URL}/get-posts?pageSize=${pageSize}`;
  const POST_URL = `${BASE_URL}/add-post`;

  const onFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value);
    setFirstNameDirty(true);
    setFirstNameValid(event.target.value !== "");
  };

  const onLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
    setLastNameDirty(true);
    setLastNameValid(event.target.value !== "");
  };

  const onMessageChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value);
    setMessageDirty(true);
    setMessageValid(event.target.value !== "");
  };

  const onSubmitAttempt = () => {
    setFirstNameDirty(true);
    setLastNameDirty(true);
    setMessageDirty(true);
  };

  const isSubmittable = () =>
    submitting ? false : firstNameValid && lastNameValid && messageValid;

  const submit = (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    setSubmitting(true);
    setSubmitError(false);

    axios
      .post(POST_URL, {
        firstName,
        lastName,
        message,
      })
      .then(() => {
        setSubmitted(true);
        refresh(readMoreClicks);
      })
      .catch(() => setSubmitError(true))
      .finally(() => setSubmitting(false));
  };

  useEffect(() => {
    setSubmittable(isSubmittable());
  }, [
    firstName,
    firstNameValid,
    lastName,
    lastNameValid,
    message,
    messageValid,
    submitting,
  ]);

  const getNext = () => {
    setStatus(Status.Updating);
    return axios
      .get(`${GET_URL}&after=${after}`)
      .then(({ data }: { data: PostsResponse }) => {
        setPosts([...posts, ...data.posts]);
        setAfter(data.after ? data.after : "");
        setReadMoreClicks(readMoreClicks + 1);
        setStatus(Status.Loaded);
      })
      .catch(() => setStatus(Status.Error));
  };

  const getRefreshNext = (
    final = false,
    posts?: postInterface[],
    after?: string
  ) => {
    return axios
      .get(`${GET_URL}${after ? `&after=${after}` : ""}`)
      .then(({ data }: { data: PostsResponse }) => {
        const newAfter = data.after ? data.after : "";
        const previousPosts = posts ? posts : [];
        const newPosts = [...previousPosts, ...data.posts];
        if (final) {
          setPosts(newPosts);
        }
        setAfter(newAfter);
        return { posts: newPosts, after: newAfter };
      });
  };

  const refresh = (
    remaining: number,
    initial = true,
    posts?: postInterface[],
    after?: string
  ) => {
    if (remaining >= 0) {
      getRefreshNext(
        remaining === 0,
        initial ? [] : posts,
        initial ? undefined : after
      ).then(({ posts, after }: { posts: postInterface[]; after: string }) =>
        refresh(remaining - 1, false, posts, after)
      );
    }
  };

  useEffect(() => {
    setStatus(Status.Loading);
    axios
      .get(GET_URL)
      .then(({ data }: { data: PostsResponse }) => {
        setPosts(data.posts);
        if (data.after) {
          setAfter(data.after);
        }
        setStatus(Status.Loaded);
      })
      .catch(() => setStatus(Status.Error));
  }, []);

  const formatDatetime = (datetime: number) => {
    const date = new Date(datetime);
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return `${
      months[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()} at ${date.getHours() % 12}:${date
      .getMinutes()
      .toString()
      .padStart(2, "0")} ${date.getHours() > 11 ? "PM" : "AM"}`;
  };

  return (
    <Section id="guest-book" className="bg-blue-pastel">
      <Container
        dividerColor="black"
        title={language === OPTION.ENGLISH ? "Guest Book" : "Album de Firmas"}
        className="text-black"
      >
        <>
          {status === Status.Loaded || status === Status.Updating ? (
            <>
              <div className="relative flex justify-center items-center mb-4">
                <div
                  className={classnames("flex-grow", { invisible: submitted })}
                >
                  <form onSubmit={submit} className="flex flex-col">
                    <div
                      className={classnames(
                        "text-center !mt-0 mb-4",
                        errorClassName,
                        {
                          invisible: !submitError,
                        }
                      )}
                    >
                      There was a problem posting your message. Please try
                      again.
                    </div>
                    <div className="mb-2">
                      <textarea
                        id="message"
                        value={message}
                        onChange={onMessageChange}
                        placeholder={
                          language === OPTION.ENGLISH
                            ? "Leave a message"
                            : "Deja un mensaje"
                        }
                        className="w-full text-black placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 px-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                      />
                      <div
                        className={classnames(errorClassName, {
                          invisible: !messageDirty || messageValid,
                        })}
                      >
                        {language === OPTION.ENGLISH
                          ? "Please provide a message."
                          : "Por favor escribe un mensaje."}
                      </div>
                    </div>
                    <div className="flex items-start">
                      <div className="flex-grow grid grid-cols-2 gap-4 mr-4">
                        <div>
                          <input
                            id="first-name"
                            type="text"
                            value={firstName}
                            onChange={onFirstNameChange}
                            placeholder={
                              language === OPTION.ENGLISH
                                ? "First Name *"
                                : "Nombre *"
                            }
                            className="w-full text-black placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 px-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                          />
                          <div
                            className={classnames(errorClassName, {
                              invisible: !firstNameDirty || firstNameValid,
                            })}
                          >
                            {language === OPTION.ENGLISH
                              ? "Please provide your first name."
                              : "Por favor escribe tu nombre."}
                          </div>
                        </div>
                        <div>
                          <input
                            id="last-name"
                            type="text"
                            value={lastName}
                            onChange={onLastNameChange}
                            placeholder={
                              language === OPTION.ENGLISH
                                ? "Last Name *"
                                : "Apellido *"
                            }
                            className="w-full text-black placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 px-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                          />
                          <div
                            className={classnames(errorClassName, {
                              invisible: !lastNameDirty || lastNameValid,
                            })}
                          >
                            {language === OPTION.ENGLISH
                              ? "Please provide your last name."
                              : "Por favor escribe tu apellido."}
                          </div>
                        </div>
                      </div>
                      <div
                        onMouseEnter={onSubmitAttempt}
                        className={classnames({
                          "cursor-not-allowed": !submittable,
                        })}
                      >
                        <button
                          type="submit"
                          className={classnames(
                            "flex items-center border text-white border-pink bg-pink rounded-md px-4 py-1",
                            {
                              "pointer-events-none": !submittable,
                              "opacity-50": !submittable,
                            }
                          )}
                          disabled={!submittable}
                          style={{ marginTop: "1px" }}
                        >
                          <Loader
                            className={classnames("mr-2", {
                              invisible: !submitting,
                            })}
                          />
                          <div>
                            {language === OPTION.ENGLISH ? "Post" : "Firma"}
                          </div>
                          <Loader className="ml-2 invisible" />
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div
                  className={classnames("absolute w-full", {
                    invisible: !submitted,
                  })}
                >
                  <div className=" flex flex-col justify-center items-center bg-white/70 80 py-16 px-4 rounded-xl text-pink md:text-lg">
                    <div>
                      {language === OPTION.ENGLISH
                        ? "Thanks for taking the time to leave your thoughts!"
                        : "¡Gracias por tomar el tiempo para dejar tus pensamientos!"}
                    </div>
                  </div>
                </div>
              </div>
              <div className="md:grid md:grid-cols-4 md:gap-6 md:grid-cols-4">
                {posts.map(({ id, firstName, lastName, datetime, message }) => (
                  <div
                    key={id}
                    className="flex flex-col bg-white rounded-xl p-4 mb-4 last:mb-0 md:mb-0"
                  >
                    <div className="mb-2 flex-grow">{message}</div>
                    <div className="flex flex-col items-end">
                      <div className="text-pink text-sm">
                        {firstName} {lastName}
                      </div>
                      <div className="text-xs">{formatDatetime(datetime)}</div>
                    </div>
                  </div>
                ))}
              </div>
              {after ? (
                <div
                  className={classnames("flex justify-center mt-4", {
                    "cursor-not-allowed": status === Status.Updating,
                  })}
                >
                  <button
                    type="button"
                    className={classnames(
                      "flex items-center border text-white border-pink bg-pink rounded-md px-4 py-1",
                      {
                        "pointer-events-none": status === Status.Updating,
                        "opacity-50": status === Status.Updating,
                      }
                    )}
                    disabled={status === Status.Updating}
                    style={{ marginTop: "1px" }}
                    onClick={getNext}
                  >
                    {status === Status.Updating ? (
                      <Loader className={classnames("mr-2")} />
                    ) : (
                      <FontAwesomeIcon
                        icon={faAnglesDown}
                        size="1x"
                        className="mr-2"
                      />
                    )}
                    <div>{language === OPTION.ENGLISH ? "More" : "Más"}</div>
                    {status === Status.Updating ? (
                      <Loader className={classnames("ml-2")} />
                    ) : (
                      <FontAwesomeIcon
                        icon={faAnglesDown}
                        size="1x"
                        className="ml-2"
                      />
                    )}
                  </button>
                </div>
              ) : null}
            </>
          ) : status === Status.Error ? (
            <div className="flex flex-col justify-center items-center bg-white py-32 px-4 text-pink">
              <FontAwesomeIcon icon={faFaceFrown} size="6x" className="mb-4" />
              <div className="text-2xl mb-2">Oops...</div>
              <div>
                {language === OPTION.ENGLISH
                  ? "Messages aren't available right this moment, but come back later to check them out or leave one yourself."
                  : "Los mensajes no están disponibles en este momento, pero vuelve más tarde para revisarlos o dejar uno tú mismo."}
              </div>
            </div>
          ) : (
            <div className="flex justify-center items-center my-24">
              <Loader size={50} />
            </div>
          )}
        </>
      </Container>
    </Section>
  );
};

export default GuestBook;

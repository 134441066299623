import React, { useContext } from "react";
import PropTypes from "prop-types";

import classnames from "classnames";
import styled from "styled-components";

import Context from "../utils/context";

import { OPTION } from "../utils/language";

const Background = styled.div`
  background: linear-gradient(
    180deg,
    ${(props: { color: string }) =>
      props.color ? `var(--${props.color})` : "transparent"},
    transparent
  );
`;

const CourtMember = ({
  age,
  background,
  children,
  className,
  image,
  name,
  school,
}: {
  age: number;
  background?: { color?: string; swirlType?: string };
  children: React.ReactNode;
  className?: string;
  image: React.ReactNode;
  name: { first: string; middle?: string; last: string };
  school: string;
}) => {
  const [language] = useContext(Context);

  const backgroundPatternStyle = {
    stroke: background?.color ? `var(--${background?.color})` : "transparent",
  };
  const backgroundPatterns = {
    single: (
      <svg
        width="570"
        height="296"
        viewBox="0 0 570 296"
        fill="none"
        className="absolute w-full h-full opacity-10"
      >
        <path
          d="M-23.2731 10.3837C-59.5466 185.069 184.822 393.164 570.026 223.252"
          strokeWidth="19.0913"
          strokeLinecap="round"
          className="stroke-orange-bright"
          style={backgroundPatternStyle}
        />
      </svg>
    ),
    double: (
      <svg
        width="450"
        height="456"
        viewBox="0 0 450 456"
        fill="none"
        className="absolute w-full h-full opacity-10"
      >
        <path
          d="M2.79126 344.968C114.983 296.842 155.739 105.902 227.174 44.6637C413.556 -115.114 690.416 1.35084 458.795 72.5772C254.317 135.457 228.984 527.846 523.938 472.02"
          strokeWidth="19.6366"
          strokeLinecap="round"
          style={backgroundPatternStyle}
        />
      </svg>
    ),
    loop: (
      <svg
        width="333"
        height="368"
        viewBox="0 0 333 368"
        fill="none"
        className="absolute h-full opacity-50"
      >
        <path
          d="M66.8194 -15.7091C-36.2736 66.7649 -31.9667 205.354 -7.63662 251.348C85.4763 427.371 341.792 317.962 305.461 251.348C261.551 170.838 -34.3645 265.094 7.63644 438.877C49.6374 612.661 299.733 591.061 322.643 565.534"
          stroke="#15B9FF"
          strokeWidth="19.6366"
          strokeLinecap="round"
          style={backgroundPatternStyle}
        />
      </svg>
    ),
  };

  return (
    <Background
      color={background?.color}
      className={classnames("relative rounded-xl text-white", className)}
    >
      {background?.swirlType ? backgroundPatterns[background?.swirlType] : null}
      <div className="p-4 text-black">
        <div className="flex flex-col">
          <div className="flex mb-4 z-10">
            <div className="mr-4">{image}</div>
            <div className="flex-grow">
              <div className="text-xl md:text-2xl z-10">
                {name.first}{" "}
                {name.middle ? `${name.middle} ${name.last}` : name.last}
              </div>
              <div className="md:text-lg z-10">
                {language === OPTION.ENGLISH ? "Age" : "Edad"} {age}
              </div>
              <div className="md:text-lg  mb-4 z-10">{school}</div>
            </div>
          </div>
          <div className="max-w-xl z-10">{children}</div>
        </div>
      </div>
    </Background>
  );
};

CourtMember.propTypes = {
  age: PropTypes.number.isRequired,
  background: PropTypes.shape({
    color: PropTypes.string,
    swirlType: PropTypes.oneOf(["single", "double", "loop"]),
  }),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
  className: PropTypes.string,
  image: PropTypes.element,
  name: PropTypes.shape({
    first: PropTypes.string.isRequired,
    middle: PropTypes.string,
    last: PropTypes.string.isRequired,
  }).isRequired,
  school: PropTypes.string.isRequired,
};

export default CourtMember;

import React, { useState } from "react";

import { useQueryParam, StringParam } from "use-query-params";

import {
  OPTION as LANGUAGE_OPTION,
  Type as LanguageType,
} from "../utils/language";

import Context from "../utils/context";

import AboutMe from "../components/sections/AboutMe";
import Court from "../components/sections/Court";
import EventDetails from "../components/sections/EventDetails";
import EventContributors from "../components/sections/EventContributors";
import GuestBook from "../components/sections/GuesBook";
import Home from "../components/sections/Home";
import Layout from "../components/Layout";
import Registry from "../components/sections/Registry";
import RSVP from "../components/sections/RSVP";

const metadata = {
  title: "Mi Quinceañera | Adriana Quiroz",
  description: "Mi Quince Party",
  robots:
    "index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1",
  canonical: "/",
  "og:locale": "en_US",
  "og:type": "website",
  "og:title": "Mi Quince | Adriana Quiroz",
  "og:description": "Mi Quince Party",
  "og:url": "/",
  "og:site_name": "Mi Quinceañera",
  "og:image": "/src/images/icon.png",
  "article:modified_time": "2022-06-22T13:57:48+00:00",
  "twitter:card": "summary_large_image",
  "twitter:label1": "Est. reading time",
  "twitter:data1": "1 minute",
};

const IndexPage = () => {
  const [languageQuery] = useQueryParam("language", StringParam);
  const [language, setLanguage] = useState<LanguageType>(
    languageQuery?.toLowerCase() === "spanish"
      ? LANGUAGE_OPTION.SPANISH
      : LANGUAGE_OPTION.ENGLISH
  );

  return (
    <Context.Provider value={[language, setLanguage]}>
      <Layout metadata={metadata}>
        <>
          <Home />
          <div className="tw flex justify-center items-center my-4" />
          <AboutMe />
          <EventDetails />
          <Court />
          <Registry />
          <GuestBook />
          <EventContributors />
          <RSVP />
        </>
      </Layout>
    </Context.Provider>
  );
};

export default IndexPage;

import React from "react";
import PropTypes from "prop-types";

import classnames from "classnames";
import { faAtom } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Container = ({
  children,
  className,
  dividerColor,
  title,
}: {
  children?: React.ReactNode;
  className?: string;
  dividerColor: string;
  title: string;
}) => (
  <div
    className={classnames("relative flex justify-center pt-4 pb-8", className)}
  >
    <div className="max-w-5xl w-full flex flex-col">
      <div className="flex-1 px-4 md:px-0 mb-4">
        <div className="mb-2">
          <h2 className="font-dancing-script text-center mb-2">{title}</h2>
          <div className="flex justify-center items-center">
            <div
              className="flex-1"
              style={{
                backgroundColor: `var(--${dividerColor})`,
                height: "1px",
              }}
            />
            <FontAwesomeIcon icon={faAtom} size="1x" className="mx-4" />
            <div
              className="flex-1"
              style={{
                backgroundColor: `var(--${dividerColor})`,
                height: "1px",
              }}
            />
          </div>
        </div>
        {children}
      </div>
    </div>
  </div>
);

Container.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
  className: PropTypes.string,
  dividerColor: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Container;

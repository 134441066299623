import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { Carousel } from "react-responsive-carousel";

import "react-responsive-carousel/lib/styles/carousel.min.css";

const Slider = ({
  items,
}: {
  className?: string;
  items: React.ReactNode[];
}) => {
  const [index, setIndex] = useState(0);
  const [myTimeout, setMyTimeout] = useState<any>(null);

  const isVideo = ({ type }: { type: string }) => type === "video";

  const play = (index: number) => {
    clearTimeout(myTimeout);
    setIndex(index);
    const element = items[index];
    if (!isVideo(element)) {
      setMyTimeout(
        setTimeout(() => setIndex((index + 1) % items.length), 5000)
      );
    }
  };

  const addVideoProps = (Component: React.ReactNode, props: object) => (
    <Component.type {...Component.props} {...props} />
  );

  const onVideoEnded = (event: object) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    event!.target!.currentTime = 0;
    setIndex((index + 1) % items.length);
  };

  useEffect(() => {
    if (!isVideo(items[0])) {
      setMyTimeout(setTimeout(() => setIndex(1 % items.length), 5000));
    }
  }, []);

  return (
    <Carousel
      selectedItem={index}
      transitionTime={1500}
      showStatus={false}
      showIndicators={false}
      onChange={play}
      showThumbs={false}
      swipeable={false}
    >
      {items.map((item, index) => (
        <div key={index} className="h-full flex items-center justify-center">
          {isVideo(item)
            ? addVideoProps(item, { onEnded: onVideoEnded })
            : item}
        </div>
      ))}
    </Carousel>
  );
};

Slider.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.element, PropTypes.string])
  ).isRequired,
};

export default Slider;

import React, { useContext } from "react";

import Context from "../../utils/context";

import Container from "../Container";
import Section from "../Section";
import Vendor from "../Vendor";
import { OPTION } from "../../utils/language";

const EventContributors = () => {
  const [language] = useContext(Context);

  return (
    <Section id="event-contributors">
      <Container
        dividerColor="white"
        title={
          language === OPTION.ENGLISH
            ? "Event Contributors"
            : "Colaboradores del Evento"
        }
      >
        <div className="grid grid-cols-3 grid-rows-4 gap-x-6 gap-y-3">
          <Vendor
            name="Tacos Torres"
            socialMedia={[
              {
                type: "Instagram",
                url: "https://www.instagram.com/tacos_torres_houston",
              },
              {
                type: "Facebook",
                url: "https://www.facebook.com/profile.php?id=100073418793015",
              },
              {
                type: "TikTok",
                url: "https://www.tiktok.com/@tacos_torres",
              },
            ]}
          />
          <Vendor
            name={
              language === OPTION.ENGLISH
                ? "Invitations & Centerpieces by Bel"
                : "Invitaciones y Decoraciones por Bel"
            }
            socialMedia={[
              {
                type: "Instagram",
                url: "https://www.instagram.com/bel2344",
              },
            ]}
          />
          <Vendor
            name={
              language === OPTION.ENGLISH
                ? "Choreography by Jennifer"
                : "Coreografía por Jennifer"
            }
            socialMedia={[
              {
                type: "Facebook",
                url: "https://www.facebook.com/Grupofolkloricodebendiciones",
              },
            ]}
          />
          <Vendor
            name='"Styles of Music" DJing Entertainment'
            socialMedia={[
              {
                type: "Facebook",
                url: "https://www.facebook.com/djstyln",
              },
            ]}
          />
          <Vendor
            name="Mariachi Sol Y Luna"
            socialMedia={[
              {
                type: "Facebook",
                url: "https://www.facebook.com/bmesa123",
              },
            ]}
          />
          <Vendor
            name="Angel's Thrones and More"
            socialMedia={[
              {
                type: "Facebook",
                url: "https://www.facebook.com/angelsthronesandmore",
              },
            ]}
          />
          <Vendor
            name="Leondro Del Sol Photography (RGV)"
            socialMedia={[
              {
                type: "Facebook",
                url: "https://www.facebook.com/RGV-Del-Sol-Photography-Videography-329939267147655",
              },
            ]}
          />
          <Vendor
            name={
              <a
                href="https://pinkflamingorgv.com"
                target="_blank"
                rel="noreferrer"
                className="underline"
              >
                Pink Flamingo RGV
              </a>
            }
            socialMedia={[
              {
                type: "Instagram",
                url: "https://www.instagram.com/pinkflamingorgv",
              },
              {
                type: "Facebook",
                url: "https://www.facebook.com/thepinkflamingorgv",
              },
            ]}
          />
          <Vendor
            name="Hair By Victoria Plata"
            socialMedia={[
              {
                type: "Instagram",
                url: "https://www.instagram.com/vidaxii",
              },
              {
                type: "Facebook",
                url: "https://www.facebook.com/victoria.plata.144",
              },
            ]}
          />
          <Vendor
            name="Miranda's Party Rental"
            socialMedia={[
              {
                type: "Facebook",
                url: "https://www.facebook.com/Mirandas-Party-Planner-Rental-1631810410244073",
              },
            ]}
          />
          <Vendor
            name="Berenice's Quinceañera Boutique"
            socialMedia={[
              {
                type: "Instagram",
                url: "https://www.instagram.com/berenices.bridal.boutique",
              },
              {
                type: "Facebook",
                url: "https://www.facebook.com/berenicesxvboutique",
              },
              {
                type: "TikTok",
                url: "https://www.tiktok.com/@berenicesbridal",
              },
            ]}
          />
          <Vendor
            name="Dresses by Olga Agostadero"
            socialMedia={[
              {
                type: "Facebook",
                url: "https://www.facebook.com/olga.agostadero",
              },
            ]}
          />
        </div>
      </Container>
    </Section>
  );
};

export default EventContributors;

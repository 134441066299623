import React, { useContext } from "react";

import { StaticImage } from "gatsby-plugin-image";

import Context from "../../utils/context";

import Container from "../Container";
import RainbowBackground from "../RainbowBackground";
import Section from "../Section";
import Slider from "../Slider";

import AdrianaVideo1 from "../../videos/adriana/1.mp4";
import AdrianaVideo2 from "../../videos/adriana/2.mp4";
import AdrianaVideo3 from "../../videos/adriana/3.mp4";
import AdrianaVideo4 from "../../videos/adriana/4.mp4";
import AdrianaVideo5 from "../../videos/adriana/5.mp4";
import { OPTION } from "../../utils/language";

const AboutMe = () => {
  const [language] = useContext(Context);

  return (
    <RainbowBackground>
      <Section
        id="about-me"
        className="flex justify-center pt-4 pb-8 text-black"
      >
        <Container
          dividerColor="white"
          title={language === OPTION.ENGLISH ? "About Me" : "Sobre Mi"}
        >
          <div className="rounded-xl bg-white p-4">
            <div className="mb-4 text-center text-pink md:text-lg">
              {language === OPTION.ENGLISH
                ? "Thank you for checking out my quince page! If you didn't already know, my name is Adriana Quiroz. I enjoy drawing competitive sports, and playing video games. I would like to thank my parents, Carlos and Denise Quiroz, for making this special event possible. I am excited to celebrate this milestone with all of you."
                : "¡Gracias por visitar mi página de quince! Si aún no lo sabías, mi nombre es Adriana Quiroz. Disfruto dibujar, los deportes competitivos y jugar videojuegos. Me gustaría dar las gracias a mis padres, Carlos y Denise Quiroz, por hacer posible este evento tan especial. Estoy emocionada de celebrar esta ocasión con todos ustedes."}
            </div>
            <Slider
              items={[
                <video width="300" height="400" autoPlay muted>
                  <source src={AdrianaVideo1} type="video/mp4" />
                </video>,
                <StaticImage
                  src="../../images/court/adriana/1.jpg"
                  placeholder="blurred"
                  width={397.5}
                  height={530}
                  alt="Adriana 1"
                />,
                <StaticImage
                  src="../../images/court/adriana/2.jpg"
                  placeholder="blurred"
                  width={397.5}
                  height={530}
                  alt="Adriana 2"
                />,
                <StaticImage
                  src="../../images/court/adriana/3.jpg"
                  placeholder="blurred"
                  width={800}
                  height={600}
                  alt="Adriana 3"
                />,
                <StaticImage
                  src="../../images/court/adriana/4.jpg"
                  placeholder="blurred"
                  width={397.5}
                  height={530}
                  alt="Adriana 4"
                />,
                <video width="300" height="400" autoPlay muted>
                  <source src={AdrianaVideo2} type="video/mp4" />
                </video>,
                <StaticImage
                  src="../../images/court/adriana/5.jpg"
                  placeholder="blurred"
                  width={397.5}
                  height={530}
                  alt="Adriana 5"
                />,
                <StaticImage
                  src="../../images/court/adriana/6.jpg"
                  placeholder="blurred"
                  width={397.5}
                  height={530}
                  alt="Adriana 6"
                />,
                <video width="933" height="700" autoPlay muted>
                  <source src={AdrianaVideo3} type="video/mp4" />
                </video>,
                <StaticImage
                  src="../../images/court/adriana/7.jpg"
                  placeholder="blurred"
                  width={397.5}
                  height={530}
                  alt="Adriana 7"
                />,
                <StaticImage
                  src="../../images/court/adriana/8.jpg"
                  placeholder="blurred"
                  width={397.5}
                  height={530}
                  alt="Adriana 8"
                />,
                <StaticImage
                  src="../../images/court/adriana/9.jpg"
                  placeholder="blurred"
                  width={397.5}
                  height={530}
                  alt="Adriana 9"
                />,
                <video width="300" height="400" autoPlay muted>
                  <source src={AdrianaVideo4} type="video/mp4" />
                </video>,
                <StaticImage
                  src="../../images/court/adriana/10.jpg"
                  placeholder="blurred"
                  width={397.5}
                  height={530}
                  alt="Adriana 10"
                />,
                <StaticImage
                  src="../../images/court/adriana/11.jpg"
                  placeholder="blurred"
                  width={397.5}
                  height={530}
                  alt="Adriana 11"
                />,
                <StaticImage
                  src="../../images/court/adriana/12.jpg"
                  placeholder="blurred"
                  width={397.5}
                  height={530}
                  alt="Adriana 12"
                />,
                <video width="300" height="400" autoPlay muted>
                  <source src={AdrianaVideo5} type="video/mp4" />
                </video>,
                <StaticImage
                  src="../../images/court/adriana/13.jpg"
                  placeholder="blurred"
                  width={800}
                  height={600}
                  alt="Adriana 13"
                />,
                <StaticImage
                  src="../../images/court/adriana/14.jpg"
                  placeholder="blurred"
                  width={397.5}
                  height={530}
                  alt="Adriana 14"
                />,
                <StaticImage
                  src="../../images/court/adriana/15.jpg"
                  placeholder="blurred"
                  width={397.5}
                  height={530}
                  alt="Adriana 15"
                />,
                <StaticImage
                  src="../../images/court/adriana/16.jpg"
                  placeholder="blurred"
                  width={397.5}
                  height={530}
                  alt="Adriana 16"
                />,
                <StaticImage
                  src="../../images/court/adriana/17.jpg"
                  placeholder="blurred"
                  width={397.5}
                  height={530}
                  alt="Adriana 17"
                />,
                <StaticImage
                  src="../../images/court/adriana/18.jpg"
                  placeholder="blurred"
                  width={397.5}
                  height={530}
                  alt="Adriana 18"
                />,
                <StaticImage
                  src="../../images/court/adriana/19.jpg"
                  placeholder="blurred"
                  width={397.5}
                  height={530}
                  alt="Adriana 19"
                />,
                <StaticImage
                  src="../../images/court/adriana/20.jpg"
                  placeholder="blurred"
                  width={397.5}
                  height={530}
                  alt="Adriana 20"
                />,
                <StaticImage
                  src="../../images/court/adriana/21.jpg"
                  placeholder="blurred"
                  width={800}
                  height={600}
                  alt="Adriana 21"
                />,
              ]}
            />
          </div>
        </Container>
      </Section>
    </RainbowBackground>
  );
};

export default AboutMe;
